import { createMuiTheme, responsiveFontSizes, Theme } from '@material-ui/core/styles';
import * as userTheme from "../data/theme.json";

const generalText = `'Montserrat', sans-serif`;
const headers = `'Cabin', sans-serif`;

export type ThemeWithAccent = Theme & {
    accent: {
        light?: string;
        main?: string;
        dark?: string;
        contrastText?: string;
    }
}

const theme =  {...responsiveFontSizes(createMuiTheme({
    palette: {
        primary: {
            light: userTheme.primary.light,
            main: userTheme.primary.main,
            dark: userTheme.primary.dark,
            contrastText: userTheme.primary.contrastText,
        },
        secondary: {
            light: userTheme.secondary.light,
            main: userTheme.secondary.main,
            dark: userTheme.secondary.dark,
            contrastText: userTheme.secondary.contrastText,
        },
    },
    typography: {
        fontFamily: generalText,
        h1: {
            fontFamily: headers,
            fontWeight: 500
        },
        h2: {
            fontFamily: headers,
            fontWeight: 500
        },
        h3: {
            fontFamily: headers,
            fontWeight: 500
        },
        h4: {
            fontFamily: headers,
            fontWeight: 500
        },
        h5: {
            fontFamily: headers,
            fontWeight: 500
        },
        h6: {
            fontFamily: headers,
            fontWeight: 500
        },
        button: {
            fontFamily: headers,
            fontWeight: 500
        }
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                body: {
                    fontFamily: generalText
                },
            },
        },
    },
})),
accent: {
    main: userTheme.accent?.main,
    dark: userTheme.accent?.dark,
    light: userTheme.accent?.light,
    contrastText: userTheme.accent?.contrastText
}};

export default theme;